import React, { useState, useRef } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Text } from "@nerdwallet/react-typography"
import styles from "./SearchInput.module.less"
import { useOutsideClickHandler } from "@src/hooks/useOutsideClickHandler"

const SELECT_KEYS = ["Enter"]

const SearchInput = props => {
  const { header, options, selectedOption, setUpdateAction, placeholder } =
    props
  const [current, setCurrent] = useState(selectedOption)
  const [open, setOpen] = useState(false)
  const [filteredOptions, setFilteredOptions] = useState([])

  const handlePressSelect = event => {
    if (SELECT_KEYS.indexOf(event.key) > -1) {
      event.preventDefault()
      setOpen(false)
      return
    }

    if (event.key === "ArrowUp" || event.key === "ArrowDown") {
      const idx = filteredOptions.findIndex(opt => opt.key === current?.key)
      const prevOption =
        event.key === "ArrowUp"
          ? filteredOptions[idx - 1] ||
            filteredOptions[filteredOptions.length - 1]
          : filteredOptions[idx + 1] || filteredOptions[0]
      setUpdateAction(prevOption.key)
      setCurrent(prevOption)
      const element = document.getElementById(`search-${prevOption.key}`)
      element.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      })
      event.preventDefault()
      return
    }
  }

  const handleClickOption = option => {
    setUpdateAction(option.key)
    setCurrent(option)
    setOpen(false)
  }

  const handleInputChange = event => {
    const value = event.target.value
    setUpdateAction(null)
    setCurrent({ key: value, value: value })
    setFilteredOptions(filterOptions(value))
    setOpen(true)
  }

  const handlePressOption = (event, option) => {
    if (SELECT_KEYS.indexOf(event.key) > -1) {
      handleClickOption(option)
    }
  }

  const filterOptions = value => {
    const result = (options || [])
      .filter(option => {
        return option.value?.toLowerCase().match(value.toLowerCase())
      })
      .sort((a, b) => a.value.localeCompare(b.value))

    return result
  }

  const wrapperRef = useRef(null)
  useOutsideClickHandler(wrapperRef, () => setOpen(false))

  return (
    <fieldset ref={wrapperRef} className={styles.fieldset}>
      {header && (
        <legend>
          <Text small>{header}</Text>
        </legend>
      )}
      <div className={styles.container}>
        <input
          type="search"
          value={current?.value}
          placeholder={placeholder}
          className={styles.input}
          onChange={handleInputChange}
          onKeyDown={handlePressSelect}
        />

        <ul
          className={classNames(styles.list, {
            [styles.openList]: open,
          })}
        >
          {filteredOptions.map(option => (
            <li
              className={classNames(styles.option, {
                [styles.selected]: option.key === current?.key,
              })}
              data-nw-search-input-option={option.key}
              data-nw-mp
              key={option.key}
              id={`search-${option.key}`}
              onClick={() => handleClickOption(option)}
              onKeyDown={e => handlePressOption(e, option)}
              tabIndex="0"
            >
              <label
                className={styles.label}
                htmlFor={option.key}
                role="button"
                aria-pressed={option.key === current?.key}
              >
                {option.value}
              </label>
            </li>
          ))}
        </ul>
      </div>
    </fieldset>
  )
}

SearchInput.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  header: PropTypes.string,
  placeholder: PropTypes.string,
  selectedOption: PropTypes.shape({
    key: PropTypes.string.isRequired,
    value: PropTypes.string,
  }),
  setUpdateAction: PropTypes.func.isRequired,
}

SearchInput.defaultProps = {
  options: [],
  header: null,
  placeholder: "Type to search",
  selectedOption: null,
}

export default SearchInput
