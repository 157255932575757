import { useState, useEffect } from "react"

export const useNaicsOptions = () => {
  const [naicsOptions, setNaicsOptions] = useState([])
  useEffect(() => {
    import("./full-naics-list.json").then(naicsJson => {
      const options = Object.keys(naicsJson).map(k => {
        return naicsJson[k]
      })
      setNaicsOptions(options)
    })
  }, [])

  return naicsOptions
}
