import React, { useState } from "react"
import PropTypes from "prop-types"

import classNames from "classnames"
import Type from "@nerdwallet/react-typography/structured-content"
import Button from "@src/components/Button"
import Box from "@src/components/Box"
import Input from "@src/components/Field/Input"
import Field from "@src/components/Field"
import SearchInput from "@src/components/SearchInput"
import { useApplyUrl } from "@src/hooks/useApplyUrl"
import { useNaicsOptions } from "@src/hooks/useNaicsOptions"
import { SIGN_FOR_INSURANCE, FUNDERA_BY_NERDWALLET } from "@src/lib/constants"
import buildTargetUrl from "@src/lib/buildTargetUrl"

import styles from "./InsuranceOnboarding.module.less"
const CTA_TYPE = "Insurance Onboarding"

const InsuranceOnboarding = ({ constrained }) => {
  const [industry, setIndustry] = useState("")
  const [fullName, setFullName] = useState("")
  const applyUrl = useApplyUrl({
    cta_type: CTA_TYPE,
  })

  const options = useNaicsOptions()

  const onSubmitForm = e => {
    e.preventDefault()
    const url = new URL(e.target.action)
    const names = fullName ? fullName.split(" ") : []
    const firstName = names.shift() || ""
    const lastName = names.pop() || ""

    const targetUrl = buildTargetUrl({
      href: url,
      industry,
      firstName,
      lastName,
    }).toString()

    window.open(targetUrl, "_blank").focus()
  }

  return (
    <Box
      className={classNames(styles.wrapper, {
        [styles.unconstrained]: !constrained,
      })}
    >
      <div className={styles.ctaContainer}>
        <form
          action={applyUrl}
          method="get"
          onSubmit={onSubmitForm}
          data-cta-type={CTA_TYPE}
        >
          <Field label="Your business industry is">
            <SearchInput
              placeholder="Type to search"
              setUpdateAction={setIndustry}
              options={options}
            />
          </Field>
          <Field label="Your name is">
            <Input
              placeholder="Full name"
              type="text"
              value={fullName}
              onChange={e => setFullName(e.target.value)}
            />
          </Field>
          <div className={styles.button}>
            <Button
              type="submit"
              primary
              loading={false}
              className="clickable"
              data-nw-mp
              data-cta-type={CTA_TYPE}
              rel="sponsored"
              aria-label={SIGN_FOR_INSURANCE}
            >
              {SIGN_FOR_INSURANCE}
            </Button>
            <Type small color="neutral-darker" className={styles.onWebsite}>
              {FUNDERA_BY_NERDWALLET}
            </Type>
          </div>
        </form>
      </div>
      <div className={styles.highlight}>
        <Type className={styles.paddingBottomBig} bold size="2">
          What&#39;s the best insurance for your business?
        </Type>
        <Type className={styles.paddingBottom} size="1">
          Answer a few quick questions and we will match you with our insurance
          partner who will help you find the right insurance policy for your
          specific business needs.
        </Type>
      </div>
    </Box>
  )
}

InsuranceOnboarding.propTypes = {
  constrained: PropTypes.bool,
}

InsuranceOnboarding.defaultProps = {
  constrained: false,
}

export default InsuranceOnboarding
